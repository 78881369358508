<template>
    <div class="config-type-picker" v-if="ready">
        <label class="mr-2 mt-2 d-flex">Тип конфига</label>
        <b-dropdown :text="envText" variant="primary">
            <b-dropdown-item
                v-for="(env, i) of envOptions" :key="i" @click="onEnvSelect"
                :data-value="env.value"
            >
                {{ env.name }}
            </b-dropdown-item>
        </b-dropdown>
        <b-dropdown :text="versionText" variant="primary" class="ml-2">
            <b-dropdown-item
                v-for="(version, i) of versionOptions" :key="i" @click="onVersionSelect"
                :data-value="version.value"
            >
                {{ version.name }}
            </b-dropdown-item>
        </b-dropdown>
        <ui-button @clicked="onSetEnvClick" class="ml-2">Применить</ui-button>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        ready: false,
        
        envText: '',
        envOptionId: 0,
        envOptions: [],
        
        versionText: '',
        versionOptionId: 0,
        versionOptions: []
    }),
    computed: {
        ...mapState('firebase', ['environment']),
        ...mapGetters('firebase', ['getConfigEnvs'])
    },
    components: {
        UiButton: () => import('../ui/UiButton')
    },
    created() {
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.onConfigChanged)
            .$on(this.FIREBASE_CONFIG_CHANGED_EVENT, this.onConfigChanged);
    },
    beforeDestroy() {
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.onConfigChanged);
    },
    watch: {
        envOptionId(newValue) {
            const envName = this.envOptions[newValue].name;
            const currentVersion = this.$store.state.firebase.envVersion;
            
            this.versionOptions = this.$store.state.firebase.environments[envName]?.map((version, i) => ({
                value: i, name: version
            }));
            this.versionOptionId = Math.max(0, this.versionOptions.findIndex(version => version.name === currentVersion));
            this.versionText = this.versionOptions[this.versionOptionId]?.name || '0.0.0';
        }
    },
    methods: {
        ...mapActions('firebase', ['getConfig']),
        ...mapMutations('firebase', ['SET_ENVIRONMENT']),
        onEnvSelect(event) {
            this.envOptionId = parseInt(event.target.dataset.value, 10);
            this.envText = this.envOptions[this.envOptionId]?.name || 'Default';
        },
        onVersionSelect(event) {
            this.versionOptionId = parseInt(event.target.dataset.value, 10);
            this.versionText = this.versionOptions[this.versionOptionId]?.name || '0.0.0';
        },
        onSetEnvClick() {
            this.SET_ENVIRONMENT({ env: this.envText, version: this.versionText });
            
            bus.$emit('show-notification-message', 'Тип конфига изменен');
        },
        onConfigChanged() {
            const currentEnvName = this.$store.state.firebase.environment;
            
            this.envOptions = this.getConfigEnvs.map((env, i) => ({ value: i, name: env }));
            this.envOptionId = Math.max(0, this.envOptions.findIndex(option => option.name === currentEnvName));
            this.envText = this.envOptions[this.envOptionId]?.name || 'Default';
            
            this.ready = true;
        }
    }
};
</script>

<style lang="scss">
.config-type-picker {
    display: flex;
    align-items: center;
    position: relative;
    
    &__dropdown {
        min-width: 200px;
    }
}
</style>
